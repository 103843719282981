import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Amplify, { Auth, Hub } from "aws-amplify";
import {
  AmplifyContainer,
  AmplifySignOut,
  AmplifyButton,
} from "@aws-amplify/ui-react";
import awsconfig from "./aws-exports";
import awsconfigOauth from "./aws-exports-oauth";
import queryString from "query-string";
import SurveyForm from "./SurveyForm";
import SurveyInput from "./SurveyInput";
import SurveyResults from "./SurveyResults";
import {
  AppBar,
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  Toolbar,
  Typography,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuIcon from "@material-ui/icons/Menu";
import "./jspreadsheet.css";
import SurveyDashboard from "./SurveyDashboard";
import { Create, Dashboard } from "@material-ui/icons";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as Survey from "survey-react";
import $ from "jquery";
import "select2";
import "select2/dist/css/select2.min.css";
import select2widgets from "./select2widget";
import select2TagboxWidget from "./select2TagboxWidget";

window["$"] = window["jQuery"] = $;
select2widgets(Survey, $);
select2TagboxWidget(Survey, $);

export interface AwsConfigOAuthProps {
  scope: string[];
  domain?: string;
  redirectSignIn?: string;
  redirectSignOut?: string;
  responseType: string;
}

export interface User {
  attributes: UserAttributes;
}

export interface UserAttributes {
  family_name: string;
  given_name: string;
}

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(8),
  },
}));

const oauth: AwsConfigOAuthProps = awsconfigOauth.oauth;
awsconfig.oauth = oauth;
Amplify.configure(awsconfig);

const App = () => {
  const classes = useStyles();

  const [user, setUser] = useState<User | null>(null);
  const [redirect, setRedirect] = useState<string | null>(null);
  useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.log(event);
      //console.log(data);
      switch (event) {
        case "signIn":
          getUser().then((userData) => setUser(userData));
          break;
        case "signOut":
          setUser(null);
          break;
        case "signIn_failure":
          //case 'cognitoHostedUI_failure':
          break;
        default:
          break;
      }
    });

    getUser().then((userData) => setUser(userData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUser = () : Promise<User> => {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => {
        console.log("Not signed in");
      });
  }

  const sendKeycloak = () => {
    const p = window.location.pathname.split("/");
    const state = p.length === 3 ? `&state=${p[1]}=${p[2]}` : "";
    const uri = `https://${oauth.domain}/oauth2/authorize?response_type=${oauth.responseType}&client_id=${awsconfig.aws_user_pools_web_client_id}&redirect_uri=${oauth.redirectSignIn}${state}`;
    window.location.replace(uri);
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button component={Link} to="/">
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button component={Link} to="/create">
          <ListItemIcon>
            <Create />
          </ListItemIcon>
          <ListItemText primary="Create new survey" />
        </ListItem>
      </List>
    </div>
  );

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isProfileMenuOpen = Boolean(anchorEl);
  const handleProfileMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  console.log("window.location.search", window.location.search);
  console.log("user, redirect", user, redirect);
  const searches = queryString.parse(window.location.search);
  if (redirect === null && searches["state"]) {
    const params = queryString.parse(searches["state"] as string);
    const keys = Object.keys(params);
    if (keys.length === 1) {
      const key = keys[0];
      const value = params[key];
      console.log(`setRedirect(/${key}/${value});`);
      setRedirect(`/${key}/${value}`);
    }
  }
  if (user && redirect) {
    window.location.replace(redirect);
    return <></>;
  }

  return (
    <div>
      {user ? (
        <>
          <Router>
            <AppBar position="absolute">
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer(true)}
                >
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" color="inherit" noWrap>
                  FemtoSurvey (prototype)
                </Typography>
                <div className={classes.grow} />
                <div>
                  {user.attributes.family_name} {user.attributes.given_name}
                </div>
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="primary-account-menu"
                  aria-haspopup="true"
                  onClick={handleProfileMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              {list()}
            </Drawer>

            <main className={classes.container}>
              <Container maxWidth="lg">
                <Switch>
                  <Route path="/create/">
                    <SurveyForm />
                  </Route>
                  <Route path="/update/:formID">
                    <SurveyForm />
                  </Route>
                  <Route path="/input/:inputKey">
                    <SurveyInput />
                  </Route>
                  <Route path="/results/:resultKey">
                    <SurveyResults />
                  </Route>
                  <Route path="/">
                    <SurveyDashboard />
                  </Route>
                </Switch>
              </Container>
            </main>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              id="primary-account-menu"
              keepMounted
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              open={isProfileMenuOpen}
              onClose={handleProfileMenuClose}
            >
              <AmplifySignOut onClick={handleProfileMenuClose} />
            </Menu>
          </Router>
        </>
      ) : (
        <AmplifyContainer>
          <AmplifyButton onClick={() => sendKeycloak()}>
            Aドメインアカウントでログイン
          </AmplifyButton>
        </AmplifyContainer>
      )}
    </div>
  );
};

export default App;
