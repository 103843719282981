/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:4cde17c1-0cdf-48a2-8431-2659321d3a07",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_lEKfEZNmJ",
    "aws_user_pools_web_client_id": "le05i4fr88ddj1v3l5atvc3p9",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "survey-20221007220456-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d12zfw8xjql0ow.cloudfront.net",
    "aws_appsync_graphqlEndpoint": "https://3ycgiku2rbbzhgmelculwqyt6i.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-kk5c337q5vhh7o6kg4hwimxxje",
    "aws_cloud_logic_custom": [
        {
            "name": "itemsApi",
            "endpoint": "https://2dd0wtyo4l.execute-api.ap-northeast-1.amazonaws.com/prod",
            "region": "ap-northeast-1"
        }
    ]
};


export default awsmobile;
