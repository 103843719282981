const awsmobile = {
  "oauth": {
      "scope": [
          "openid",
          "aws.cognito.signin.user.admin"
      ],
      "domain": process.env.REACT_APP_COGNITO_DOMAIN,
      "redirectSignIn": process.env.REACT_APP_COGNITO_REDIRECTURL,
      "redirectSignOut": process.env.REACT_APP_COGNITO_REDIRECTURL,
      "responseType": "code"
  }
};

export default awsmobile;
